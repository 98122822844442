// Import React Library
import React from 'react';

// Import UseState Hook
import { useState } from 'react';

// Import Carousel From Bootstrap
import Carousel from 'react-bootstrap/Carousel';

// Import Images
import premoldeadoDelEste1 from '../assets/images/our-works/premoldeados-del-este-1.jpeg';
import premoldeadoDelEste2 from '../assets/images/our-works/premoldeados-del-este-2.jpeg';
import premoldeadoDelEste3 from '../assets/images/our-works/premoldeados-del-este-3.jpeg';
import premoldeadoDelEste4 from '../assets/images/our-works/premoldeados-del-este-4.jpeg';
import premoldeadoDelEste5 from '../assets/images/our-works/premoldeados-del-este-5.jpeg';
import premoldeadoDelEste6 from '../assets/images/our-works/premoldeados-del-este-6.jpeg';
import premoldeadoDelEste7 from '../assets/images/our-works/premoldeados-del-este-7.jpeg';
import premoldeadoDelEste8 from '../assets/images/our-works/premoldeados-del-este-8.jpeg';
import premoldeadoDelEste9 from '../assets/images/our-works/premoldeados-del-este-9.jpeg';
import premoldeadoDelEste10 from '../assets/images/our-works/premoldeados-del-este-10.jpeg';
import premoldeadoDelEste11 from '../assets/images/our-works/premoldeados-del-este-11.jpeg';
import premoldeadoDelEste12 from '../assets/images/our-works/premoldeados-del-este-12.jpeg';
import premoldeadoDelEste13 from '../assets/images/our-works/premoldeados-del-este-13.jpeg';
import premoldeadoDelEste14 from '../assets/images/our-works/premoldeados-del-este-14.jpeg';
import premoldeadoDelEste15 from '../assets/images/our-works/premoldeados-del-este-15.jpeg';


function ProductsCarousel() {
    const [mobileVersion , setMobileVersion] = useState(false);

    // Resize screen event
    window.addEventListener('resize', () =>{
        if(window.innerWidth > 1400){
            setMobileVersion(false);
        }else{
            setMobileVersion(true);
        }
    });

  return (
    <div className='products-carousel'>
        <h2>Nuestros productos instalados</h2>
        <span></span>

        <Carousel data-bs-theme="dark" className='carousel-premoldeados-del-este'>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste1}
                alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste2}
                alt="Second slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste3}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste4}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste5}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste6}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste7}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste8}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste9}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste10}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste11}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste12}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste13}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste14}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={premoldeadoDelEste15}
                alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  );
}

export default ProductsCarousel;